import type { ISbStoriesParams, ISbStoryData, StoryblokBridgeConfigV2 } from '@storyblok/vue';
import { useState } from 'nuxt/app';
import { useI18n } from 'vue-i18n';
import useOrgId from '@/utils/useOrgId';
import useAsyncCommon from '@/utils/storyblok/useAsyncCommon';
import { getStoryWithFallback } from '@/api';
import resolve_relations from '@/storyblok/resolve_relations';

/**
 * Modified version of useAsyncStoryblok that provides a fallback mechanism for both
 * branding (with default to Vonage) and locale (with default to en).
 *
 * The main purpose is to create a list of slugs to look for in priority order and then
 * grab the first one that is returned if any are found.
 */
export default async function useAsyncStoryblokWithFallback<T extends ISbStoryData = ISbStoryData>(
  basePath: string,
  storyPath: string,
  apiOptions: ISbStoriesParams & { find_by?: string } = {},
  bridgeOptions: StoryblokBridgeConfigV2 = {}
) {
  const orgId = useOrgId();
  const i18n = useI18n();
  const url = `${orgId.value}/${basePath}/${i18n.locale.value}/${storyPath}`;
  const uniqueKey = `${JSON.stringify(apiOptions)}${url}`;
  const story = useState<T>(`${uniqueKey}-state`);

  return useAsyncCommon(
    // @ts-ignore
    story,
    () =>
      getStoryWithFallback(i18n.locale.value, basePath, storyPath, {
        ...apiOptions,
        // language: i18n.locale.value,
        resolve_relations,
      }),
    bridgeOptions
  );
}
