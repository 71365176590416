<template>
  <GenericPage>
    <StoryblokComponent v-if="story" v-bind="componentProps" />
  </GenericPage>
</template>

<script setup lang="ts">
import GenericPage from '@/components/pages/GenericPage.vue';
import { computed, type PropType } from 'vue';
import type { ISbStoryData } from '@storyblok/vue';
import useAsyncStoryblokWithFallback from '@/utils/storyblok/useAsycStoryblokWithFallback';

type AdditionalPropertiesFunction = <T>(story: ISbStoryData<T>) => Record<string, unknown>;

const props = defineProps({
  storyblokBasePath: { type: String, required: true },
  storyblokStoryPath: { type: String, required: true },
  additionalProperties: { type: Function as PropType<AdditionalPropertiesFunction> },
});

const story = await useAsyncStoryblokWithFallback(props.storyblokBasePath, props.storyblokStoryPath);
const componentProps = computed(() => ({
  id: story.value.id,
  uuid: story.value.uuid,
  blok: story.value.content,
  ...(props.additionalProperties ? props.additionalProperties(story.value) : {}),
}));
</script>
